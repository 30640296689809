import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import Link from "@/utils/link";

//import { Menu, Transition, Dialog } from "@headlessui/react";
import Menu from "@/ui/Menu";

import { useAuth } from "@/lib/auth";
import { readDocument } from "@/lib/db";

import {
    MenuIcon,
    LoginIcon,
    LogoutIcon,
    MailIcon,
    HeartIcon,
    BookOpenIcon,
    AcademicCapIcon,
    InformationCircleIcon,
    QuestionMarkCircleIcon,
    HomeIcon,
} from "@heroicons/react/outline";
import { UserIcon, UserCircleIcon } from "@heroicons/react/solid";

import SignUpSignIn from "@/components/user/SignUpSignIn";
import { isSignedIn } from "@/components/user/UserUtils";

const HomeHeaderMenu = () => {
    const auth = useAuth();
    const [user, setUser] = useState({});
    const [openSignUp, setOpenSignUp] = useState(false);
    const [openSignIn, setOpenSignIn] = useState(false);
    const [communities, setCommunities] = useState([]);
    const { favourites } = useSelector((state) => state.local);

    const userId = auth.user ? auth.user.uid : "";

    //console.log("userId", userId, auth, isSignedIn(auth, true));

    useEffect(() => {
        const getUser = async (uid) => {
            const user = await readDocument("users", uid);
            setUser(user);
        };

        if (userId) {
            getUser(userId);
        }
    }, [userId]);

    const activeFavorites = Object.keys(favourites).filter((nameId) => favourites[nameId]);
    const searchBody = JSON.stringify({ nameIds: activeFavorites });

    useEffect(() => {
        const fetchData = async () => {
            const res = await fetch("/api/search", {
                method: "POST",
                body: searchBody,
            });

            const data = await res.json();

            if (data.data && data.data.communitys) {
                setCommunities(data.data.communitys);
            } else {
                setCommunities([]);
            }
        };

        fetchData();
    }, [searchBody]);

    const handleSignOut = () => {
        auth.signOut();
        setOpenSignUp(false);
        setOpenSignIn(false);
    };

    return (
        <div>
            <Menu placement="bottom-end">
                <Menu.Button className="mr-1 sm:mr-2 sm:px-2">
                    <div className="px-3 py-1 hidden sm:flex items-center rounded-full text-white bg-primary-500 hover:bg-primary-400">
                        <div className="mr-2 font-black">
                            {/*isSignedIn(auth, true) ? "My Account" : "Sign Up"*/}
                            My Favorites ({communities.length})
                        </div>
                        <UserCircleIcon className="w-8 h-8" />
                    </div>

                    <div className="mt-0.5 p-2 flex sm:hidden text-primary-500">
                        <MenuIcon className="w-6 h-6" />
                    </div>
                </Menu.Button>

                <Menu.Items
                    className={`absolute z-10 right-2 w-56 mt-0 origin-top-right bg-gray-50 
                                 rounded overflow-hidden shadow-lg ring-1 ring-black ring-opacity-5
                                 border border-gray-300 focus:outline-none`}
                >
                    {isSignedIn(auth, true) ? (
                        <Menu.Item className="w-full">
                            <Link href="/my-account">
                                <a className="w-full flex items-center px-4 py-2 hover:bg-gray-200">
                                    <UserIcon className="w-6 h-6 p-1 mr-4 text-white bg-primary-500 rounded-full" />
                                    <span className="">
                                        {user && user.name ? user.name : "My Account"}
                                    </span>
                                </a>
                            </Link>
                        </Menu.Item>
                    ) : null}

                    {communities.length > 0 ? <hr /> : null}

                    {/* List all communities */}

                    {communities
                        .filter((_, i) => i < 5)
                        .map((community) => (
                            <Menu.Item key={community.nameId} className="w-full">
                                <Link href={`/community/${community.nameId}`}>
                                    <a className="w-full flex items-center px-4 py-1 hover:bg-gray-200 text-sm text-left">
                                        <HomeIcon className="shrink-0 w-5 h-5 mr-4 text-primary-400" />
                                        <span className="">{community.name}</span>
                                    </a>
                                </Link>
                            </Menu.Item>
                        ))}

                    <hr />

                    <Menu.Item className="w-full">
                        <Link href="/favorites">
                            <a className="w-full flex items-center px-4 py-2 hover:bg-gray-200">
                                <HeartIcon className="w-5 h-5 mr-4 text-primary-400" />
                                <span className="">
                                    All Favorites ({communities.length})
                                </span>
                            </a>
                        </Link>
                    </Menu.Item>

                    <div className="block sm:hidden">
                        <hr />

                        {/*<Menu.Item className="w-full">
                            <Link href="/about-us">
                                <a className="w-full flex items-center px-4 py-2 hover:bg-gray-200">
                                    <InformationCircleIcon className="w-5 h-5 mr-4 text-primary-400" />
                                    <span className="">About Us</span>
                                </a>
                            </Link>
                        </Menu.Item>*/}

                        <Menu.Item className="w-full">
                            <Link href="/resources">
                                <a className="w-full flex items-center px-4 py-2 hover:bg-gray-200">
                                    <AcademicCapIcon className="w-5 h-5 mr-4 text-primary-400" />
                                    <span className="">Resources</span>
                                </a>
                            </Link>
                        </Menu.Item>

                        {/*<Menu.Item className="w-full">
                            <Link href="/how-it-works">
                                <a className="w-full flex items-center px-4 py-2 hover:bg-gray-200">
                                    <QuestionMarkCircleIcon className="w-5 h-5 mr-4 text-primary-400" />
                                    <span className="">How it Works</span>
                                </a>
                            </Link>
                        </Menu.Item>*/}
                    </div>

                    <hr />

                    {isSignedIn(auth, true) ? (
                        <Menu.Item className="w-full">
                            <button
                                className="w-full flex items-center px-4 py-2 hover:bg-gray-200"
                                onClick={handleSignOut}
                            >
                                <LogoutIcon className="w-5 h-5 mr-4 text-primary-400" />
                                <span className="">Sign Out</span>
                            </button>
                        </Menu.Item>
                    ) : (
                        <>
                            <Menu.Item className="w-full">
                                <button
                                    className="w-full flex items-center px-4 py-2 hover:bg-gray-200"
                                    onClick={() => setOpenSignIn(true)}
                                >
                                    <LoginIcon className="w-5 h-5 mr-4 text-primary-400" />
                                    <span className="">Sign In</span>
                                </button>
                            </Menu.Item>

                            <Menu.Item className="w-full">
                                <button
                                    className="w-full flex items-center px-4 py-2 hover:bg-gray-200"
                                    onClick={() => {
                                        setOpenSignUp(true);

                                        gtag("event", "conversion", {
                                            send_to: "AW-10778046073/z0kvCMDQspMaEPncr5Mo",
                                        });
                                    }}
                                >
                                    <MailIcon className="w-5 h-5 mr-4 text-primary-400" />
                                    <span className="">Sign Up</span>
                                </button>
                            </Menu.Item>
                        </>
                    )}
                </Menu.Items>
            </Menu>

            <SignUpSignIn
                openSignUp={openSignUp}
                openSignIn={openSignIn}
                setOpenSignUp={setOpenSignUp}
                setOpenSignIn={setOpenSignIn}
            />
        </div>
    );
};

export default HomeHeaderMenu;
